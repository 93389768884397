import dayjs from "dayjs";

function getYears(date: string): string {
  const birthDay = dayjs(date);
  const now = dayjs();
  const yearsOld = now.diff(birthDay, "year").toString();
  if (yearsOld == "0") {
    const monthOld = dayjs().diff(birthDay, "month").toString();
    if (monthOld == "0") {
      const daysOld = dayjs().diff(birthDay, "day").toString();
      if (daysOld.endsWith("1")) return `${daysOld} день`;
      if (
        daysOld.endsWith("2") ||
        daysOld.endsWith("3") ||
        daysOld.endsWith("4")
      )
        return `${daysOld} дня`;
      return `${daysOld} дней`;
    }

    if (monthOld.endsWith("1")) return `${monthOld} месяц`;
    if (
      monthOld.endsWith("2") ||
      monthOld.endsWith("3") ||
      monthOld.endsWith("4")
    )
      return `${monthOld} месяца`;
    return `${monthOld} месяцев`;
  }
  if (yearsOld.endsWith("1")) return `${yearsOld} год`;
  if (
    yearsOld.endsWith("2") ||
    yearsOld.endsWith("3") ||
    yearsOld.endsWith("4")
  )
    return `${yearsOld} года`;
  return `${yearsOld} лет`;
}

function saveData(dataurl: string, fileName: string) {
  const a = document.createElement("a") as HTMLAnchorElement;
  document.body.appendChild(a);
  a.setAttribute("style", "display: none;");
  a.href = dataurl;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(dataurl);
  a.remove();
}

function getBase64(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}
export default { getYears, saveData, getBase64 };
